// body { padding-top: 70px; }
p { font-size: 16px !important; }

.hidden { display: none; }

.img-responsive > img {
    width: 100%;
    height: auto;
    max-height: 360px;
}

.img-zoom {
    position: absolute;
    top: calc(50% - 0.5em);
    left: calc(50% - 0.5em);
    font-size: 4em;
    color:#d6d6d6;
}
