.brand {
    font-family: 'Sacramento', cursive;
    // font-size: 36px;
	color:#ffffff;
	
    &-semi-dark {
        font-family: 'Sacramento', cursive;
        // font-size: 36px;
		color: $brand-danger;
		color: #FF9800
	}
	
	&-dark {
        font-family: 'Sacramento', cursive;
        // font-size: 26px;
        color: $brand-primary;
        margin-right: 2px;
    }

    &-big { font-size: 36px; }
    &-small { font-size: 22px; }
}
