a.featured {
    border-left: 4px solid #9c27b0;
    padding-left: 5px;
}

a.unpublished {
    border-left: 4px solid #9E9E9E;
    background-color: #e5e5e5;
    background-image: repeating-linear-gradient(45deg, transparent, transparent 35px, rgba(255,255,255,.5) 35px, rgba(255,255,255,.5) 70px);
    padding-left: 5px;
}
a.disabled {
    cursor: not-allowed;
}

a.text-light:hover {
    color: #212529!important;
}
