.esz-icon {
    display: inline-block;
    font-size: 14px !important;
    border-radius: 50%;
    color: #f5f5f5;
    background-color: $purple-500;
    height: 25px;
    padding: 5px;
    text-align: center;
    width: 25px;
    min-width: 25px;
    margin: 0 5px 0 0;
}

%icon-big-square {
    width: 70px;
    height: 70px;
    display: inline-flex !important;
    align-items: center !important;
    justify-content: center !important;
    font-size: 3em !important;
    color: white !important;
}

.icon-bs {
    @extend %icon-big-square;
}

.icon-bs-primary {
    @extend %icon-big-square;
    background-color: $purple-500;
}
.icon-bs-info {
    @extend %icon-big-square;
    background-color: $cyan-500;
}
.icon-bs-success {
    @extend %icon-big-square;
    background-color: $green-500;
}
.icon-bs-warning {
    @extend %icon-big-square;
    background-color: $orange-500;
}
.icon-bs-danger {
    @extend %icon-big-square;
    background-color: $red-500;
}
.icon-bs-rose {
    @extend %icon-big-square;
    background-color: $pink-500;
}
.icon-bs-inverse {
    @extend %icon-big-square;
    background-color: $black-color;
}

.fa-custom {
    padding: 5px 0 0 0;
    margin: 0 5px 0 0;
    min-width: 30px;
    text-align: center;
}
