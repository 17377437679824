.H_ib_content {
    min-width: auto!important;
}

/*Fb Comments Width Fix*/
.fb_iframe_widget_fluid_desktop,
.fb_iframe_widget_fluid_desktop span,
.fb_iframe_widget_fluid_desktop iframe {
    max-width: 100% !important;
    width: 100% !important;
 }


 li.active > a.nav-link {
     color: #FF9800 !important;
 }
