.bmd-form-group {
    & label { color: #3C4858; }
    & label.error { color: red; }
    & label.valid { color: green; }
}

.form-control {
    background-color: #fafafa;
    padding: 3px 5px;
}
