body {
    position: relative;
}

.container {
    margin: 0 auto
}

.content {
    float: left;
    width: 80%;
}

.sidebar {
    float: left;
    width: 20%;
}

.wrapper {
    width: 100%;
}
