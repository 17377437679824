.navbar {
    padding-left: 0;
    margin: 0;
    border-radius: 0;
}

.nav li {
    display: inline;
    list-style-type: none;
}

nav {
    width: 100%;
}

.navbar-collapse .navbar-nav .dropdown-menu {
    & .nav-item .nav-link {
        color: $purple-500;
        &:hover { color:white; }
    }
}

.backToTop {
    display: none;
    position: fixed;
    right: 5px;
    bottom: 65px;
    width: 40px;
    height: 40px;
    background: url(/static/img/back-to-top.png) no-repeat center;
    border: 1px solid #fff;
    box-shadow: 0 0 2px 2px rgba(120, 120, 120, 0.2);
    z-index: 10;
    padding: 0;
}
