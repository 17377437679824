.card {
    & .card-header {
        // padding: 0.2rem 15px;
        margin: -10px 0 0 0;
        &:not([class*=header-]) {
            box-shadow: none;
        }
    }
    & .card-title {
        margin-top: 0;
    }
    &-footer {
        padding: .9375rem 1rem !important;
        background-color: rgba(0,0,0,.03) !important;
        border-top: 1px solid rgba(0,0,0,.125) !important;
        border-radius: 0 !important;
    }
}

.card-body {
    padding: .9375rem 1rem;
    &.featured {
        border-left: 4px solid #9c27b0;
        border-top-left-radius: 4px;
    }
    &.unpublished {
        border-left: 4px solid #9E9E9E;
        border-top-left-radius: 4px;
        background-color: #e5e5e5;
        background-image: repeating-linear-gradient(45deg, transparent, transparent 35px, rgba(255,255,255,.5) 35px, rgba(255,255,255,.5) 70px);
    }
}

// add Fontawesome plus/minus icon to card items
.card-header a {
    display: block;
    padding: 0 10px;
    font-size: 1.4em;
    &:before {
        content:"\f055";
        float: right !important;
        font-family: FontAwesome;
    }
}

.card > [aria-expanded="true"] > .card-header > a:before {
        float: right !important;
        content: "\f056";
}
