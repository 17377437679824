.carousel-item-fullscreen {
  margin-top: -70px;
  height: 100vh;
  min-height: 300px;
  background: no-repeat center center scroll;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

/*---Firefox Bug Fix --*/
.carousel-item-fullscreen {
  transition: -webkit-transform 0.5s ease;
  transition: transform 0.5s ease;
  transition: transform 0.5s ease, -webkit-transform 0.5s ease;
  -webkit-backface-visibility: visible;
          backface-visibility: visible;
}

.carousel-caption-fullscreen {
  bottom: 10%;

  & h2 {
    font-family: 'KoHo', sans-serif;
    text-shadow: 2px 2px 4px #000000;
    color: #fff;
  }
  @media only screen and (max-width: 576px ) { h2 { font-size: 1.5rem; } }
  @media only screen and (min-width: 768px ) { h2 { font-size: 2.9rem; } }
  @media only screen and (min-width: 992px ) { h2 { font-size: 2.3rem; } }
}

.carousel-down-indicator {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 55px;
    z-index: 30;
    text-align: center;
    font-size: 2em;
    // padding: 5px;
    color: white;
}

.carousel-indicators.outside  {
    position: relative !important;
    bottom: -10px;
}

.carousel .carousel-caption {
    margin-bottom: 65px;
}
