.breadcrumb {
    background-color: white;
    margin: 1rem 0;
    padding: 0.2rem 1rem;
    font-size: 0.9em;
}

.breadcrumb-item.active {
    font-weight: 300;
    &>a {
        color: grey;
    }
}
