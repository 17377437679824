.esz-footer {
    background: #d6d6d6;
    margin-top: 2em;
    padding-bottom: 0;
    border-top: 3px solid #ff98008c;

    p { font-size: 14px !important; }

    & .footer-contacts>div {
        display: flex;
        text-align: left;
    }
}

.footer-navigation {
    text-align: left;
    list-style-type: none;
    &>li { margin-bottom: 3px; }
}
