h1,
h2 {
    & p {
        color: rebeccapurple;
        margin: 0;
    }
}

@media screen and (max-width: 600px) {
    h2 { font-size: 2em; }
}

.page-title {
    margin-bottom: -0.5em;
}
