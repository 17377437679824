table.js-calendar-table {
    text-align: center;
    & .weekend { background: #9e9e9e1c; }
    & .events, .multiday-events { 
        background: #9c27b03b;
        &:hover { background: #a641b89c; }
    }
    & .today  {
        font-weight: bold;
        border: 1px solid gray;
    }
}

.events-header {
    border-bottom: 1px solid #ddd;
    padding-bottom: 6px;
    font-weight: bold;
}

.event-item {
    display: flex;
    margin-bottom: 10px;
}
