.esz-ul {
    padding-left: 15px;
}

.esz-ul-icon {
    padding-left: 15px;
    list-style-type: none;
    & li:before {
        font-family: 'Font Awesome 5 Free';
        content: '\f00c';
        color: $purple-500;
        padding: 5px 5px 5px 0;
    }
}


.fields-ul {
    list-style-type: none;
    padding: 0;

    li { 
        margin: 0;
        padding: 0;
        display: block;
        position: relative;
        overflow: auto;
    }

    &>li>a {
        font-size: 0.9em;
        padding: 8px 0 8px 18px;
        display: block;
        border-bottom: 1px solid #9e9e9e6e;
        color: #3a3838;
    }

    li>a:before {
        content: "";
        display: inline-block;
        width: 0;
        height: 0;
        border-top: 4px solid transparent;
        border-bottom: 4px solid transparent;
        border-left: 4px solid #333;
        margin: 7px 0 0 -9px;
        position: absolute;
    }
}

.fields-ul-footer {
    list-style-type: none;
    padding: 0;

    li { 
        margin: 0;
        padding: 0;
        display: block;
        position: relative;
        overflow: auto;
    }

    &>li>a {
        font-size: 0.9em;
        padding: 4px 0 4px 18px;
        display: block;
    }

    li>a:before {
        content: "";
        display: inline-block;
        width: 0;
        height: 0;
        border-top: 4px solid transparent;
        border-bottom: 4px solid transparent;
        border-left: 4px solid $text-primary;
        margin: 7px 0 0 -9px;
        position: absolute;
    }
}

.list-small-left-padding {
    padding-left: 10px;
}

%custom-list-shared {
    list-style: none;
    padding-left: 0;
    padding-right: 0;

    li {
        line-height: 24px;
        margin-bottom: 5px;
        padding-left: 5px;
        display: flex;
        align-items: baseline;

        &:before {
            font: normal normal normal 22px/1 FontAwesome;
            color: $purple-500;
            margin-right: 0.3em;
            font-size: 1em;
        }
    }
}

.list-icon-arrow-right-primary {
    @extend %custom-list-shared;
    li:before { content: "\f061"; color: $purple-500; }
}
.list-icon-arrow-right-info {
    @extend %custom-list-shared;
    li:before { content: "\f061"; color: $cyan-500; }
}
.list-icon-arrow-right-success {
    @extend %custom-list-shared;
    li:before { content: "\f061"; color: $green-500; }
}
.list-icon-arrow-right-warning {
    @extend %custom-list-shared;
    li:before { content: "\f061"; color: $orange-500; }
}
.list-icon-arrow-right-danger {
    @extend %custom-list-shared;
    li:before { content: "\f061"; color: $red-500; }
}


.list-icon-arrow-circle-right-primary {
    @extend %custom-list-shared;
    li:before { content: "\f0a9"; color: $purple-500; }
}
.list-icon-arrow-circle-right-info {
    @extend %custom-list-shared;
    li:before { content: "\f0a9"; color: $cyan-500; }
}
.list-icon-arrow-circle-right-success {
    @extend %custom-list-shared;
    li:before { content: "\f0a9"; color: $green-500; }
}
.list-icon-arrow-circle-right-warning {
    @extend %custom-list-shared;
    li:before { content: "\f0a9"; color: $orange-500; }
}
.list-icon-arrow-circle-right-danger {
    @extend %custom-list-shared;
    li:before { content: "\f0a9"; color: $red-500; }
}


.list-icon-check-primary {
    @extend %custom-list-shared;
    li:before { content: "\f00c"; color: $purple-500; }
}
.list-icon-check-info {
    @extend %custom-list-shared;
    li:before { content: "\f00c"; color: $cyan-500; }
}
.list-icon-check-success {
    @extend %custom-list-shared;
    li:before { content: "\f00c"; color: $green-500; }
}
.list-icon-check-warning {
    @extend %custom-list-shared;
    li:before { content: "\f00c"; color: $orange-500; }
}
.list-icon-check-danger {
    @extend %custom-list-shared;
    li:before { content: "\f00c"; color: $red-500; }
}


.list-icon-check-circle-primary {
    @extend %custom-list-shared;
    li:before { content: "\f058"; color: $purple-500; }
}
.list-icon-check-circle-info {
    @extend %custom-list-shared;
    li:before { content: "\f058"; color: $cyan-500; }
}
.list-icon-check-circle-success {
    @extend %custom-list-shared;
    li:before { content: "\f058"; color: $green-500; }
}
.list-icon-check-circle-warning {
    @extend %custom-list-shared;
    li:before { content: "\f058"; color: $orange-500; }
}
.list-icon-check-circle-danger {
    @extend %custom-list-shared;
    li:before { content: "\f058"; color: $red-500; }
}
