blockquote {
	font-style: italic;
	font-size: 1.2em !important;
	line-height: 170%;
	color: #555;
	margin: 2em 0;
	padding: 0 3em !important;
	border: none !important;
	background-image: url('../../img/icon_quotation_mark_highlight.svg');
	background-repeat: no-repeat;
    background-size: 40px;
    background-position: 0 12px;
}
