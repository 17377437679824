.esz-hr {
    border: 0;
    height: 1px;
    background-image: linear-gradient(to right, transparent, #666, transparent);
}

.hr-page-title {
    border: 0;
    height: 2px;
    background-image: linear-gradient(to left, $purple-300, transparent);
    margin-bottom: 2.5em;
    margin-top: -1.6em;
}
