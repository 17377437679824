.category.active,
li.active,
li.a.active {
    font-weight: bold;
}

.lead>p {
    font-size: 1.25rem;
    font-weight: 300;
}
